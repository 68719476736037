<template>
  <div>
    <el-dialog
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      :before-close="cancellationFn"
    >
      <div slot="title" style="color: #409eff">历史任务</div>

      <el-table v-loading="loading" :data="list" style="width: 100%" height="500" border>
        <el-table-column align="center" fixed="left" type="index" label="序号" width="70">
        </el-table-column>
        <el-table-column
          label="记录时间"
          fixed="left"
          prop="logDate"
          min-width="105"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.logDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column
          label="任务名称"
          fixed="left"
          align="center"
          prop="taskName"
          min-width="200"
        >
        </el-table-column>
        <el-table-column label="任务类型" align="center" prop="isUnitPriceTask" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.isUnitPriceTask == 0 ? '基于具体任务' : '基于具体单价' }}
          </template>
        </el-table-column>
        <el-table-column label="任务价格" align="center" prop="taskPrice" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.taskPrice | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="任务进度" prop="taskProgress" min-width="80" align="center">
          <template slot-scope="scope"> {{ (scope.row.taskProgress * 100).toFixed(1) }}% </template>
        </el-table-column>
        <el-table-column label="任务单价" align="center" prop="taskUnitPrice" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.taskUnitPrice | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="计件单元" align="center" prop="quantityCompleted" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.quantityCompleted | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="任务审批工时(H)" align="center" prop="auditHour" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.auditHour | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="任务薪酬发放" align="center" prop="taskSalary" min-width="90">
          <template slot-scope="scope">
            {{ scope.row.taskSalary | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="执行人" align="center" prop="executor" min-width="90">
        </el-table-column>
        <el-table-column label="创建人" align="center" prop="assigneeName" min-width="90">
        </el-table-column>
        <el-table-column label="任务所属部门" align="center" prop="department" min-width="110">
        </el-table-column>

        <el-table-column label="创建任务时间" prop="createdDate" min-width="105" align="center">
          <template slot-scope="scope">
            {{ scope.row.createdDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column label="预计完成时间" prop="completeDate" min-width="105" align="center">
          <template slot-scope="scope">
            {{ scope.row.completeDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column label="最新修改时间" prop="lastModifyDate" min-width="105" align="center">
          <template slot-scope="scope">
            {{ scope.row.lastModifyDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column label="是否完成" prop="isFlag" min-width="80" align="center">
          <template slot-scope="scope">
            {{ scope.row.isFlag == 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="任务内容" align="center" prop="taskContent" min-width="200">
        </el-table-column>
      </el-table>
      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button
          type="primary"
          style="min-width: 120px; margin: 0 25px"
          plain
          :loading="loading"
          @click="cancellationFn"
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ReviewTask',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 80,
    },
    projectId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      list: [],
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.getData(this.projectId)
        }
        this.isDialog = newVal
      },
    },
  },
  computed: {},
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    getData(projectId) {
      this.loading = true
      this.$api.project
        .projectTaskAssginDataView({
          id: projectId,
        })
        .then(res => {
          this.list = []
          this.list = res.data ? res.data : []

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    cancellationFn() {
      this.$emit('update:isShow', false)
    },
  },
}
</script>

<style lang="scss" scoped></style>
